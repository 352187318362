
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "customer-sme-hd",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs(
        "SME Help Desk Repayment Assistance (RA) Request Form",
        ["Customer"]
      );
    });

    return {};
  },
  data() {
    let isLoading = true;
    const route = useRoute();
    const cstmr_id = route.params.id ?? null;
    const smeHDUrl = "#/customers/details/" + cstmr_id + "/sme-hd/plan/";

    return {
      smeHDUrl,
    };
  },
});
